import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default [
  {
    path: '/dashboard/analytics',
    name: 'analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/hearings',
    name: 'court-hearing',
    component: () => import('@/views/dashboard/hearings/Hearing.vue'),
    meta: {
      pageTitle: 'Court Hearing',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Hearings',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/news',
    name: 'lawtrend-news',
    component: () => import('@/views/news/LawTrendNews.vue'),
    meta: {
      pageTitle: 'Legal Updates',
      breadcrumb: [
        {
          text: 'News',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/news/news-preview/:id',
    name: 'news-preview',
    component: () => import('@/views/news/news-preview/NewsPreview.vue'),
    meta: {
      pageTitle: 'Legal Updates',
      breadcrumb: [
        {
          text: 'News',
          active: true
        },
        {
          text: 'Preview',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/causelist',
    name: 'cause-list',
    component: () => import('@/views/dashboard/causelist/CauseList.vue'),
    meta: {
      pageTitle: 'Cause List',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Cause List',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/displayboard',
    name: 'display-board',
    component: () => import('@/views/dashboard/displayboard/DisplayBoard.vue'),
    meta: {
      pageTitle: 'Display Board',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Display Board',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/matters',
    name: 'matters',
    component: () => import('@/views/dashboard/matters/Matters.vue'),
    meta: {
      pageTitle: 'Matters',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Matters',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: true }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/matters/matter-preview/:id',
    name: 'dashboard-matter-preview',
    component: () =>
      import('@/views/dashboard/matters/matter-preview/MatterPreview.vue'),
    meta: {
      pageTitle: 'Matter Detail',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Matters',
          active: true
        },
        {
          text: 'Preview',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/matters/other-matter-preview/:id',
    name: 'dashboard-other-matter-preview',
    component: () =>
      import('@/views/dashboard/matters/matter-preview/OtherMatterPreview.vue'),
    meta: {
      pageTitle: 'Matter Detail',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Matters',
          active: true
        },
        {
          text: 'Preview',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/searchFilter',
    name: 'search-filter',
    component: () => import('@/views/dashboard/searchFilter/SearchFilter.vue'),
    meta: {
      pageTitle: 'Search Filter',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        },
        {
          text: 'Matters',
          active: true
        },
        {
          text: 'Search Filter',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: true }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/dashboard/notification',
    name: 'notification-screen',
    component: () =>
      import('@/views/dashboard/notification/NotificationScreen.vue'),
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  }
]
