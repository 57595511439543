import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    // courtsData: {}
    settingLoading: false,
    plansList: []
  },
  getters: {
    // getAllMatters: (state) => {
    //   return state.allMatters 
    // }
  },
  mutations: {
    changeSettingLoading(state, val) {
      state.settingLoading = val
    },
    changePlansList(state, list) {
      state.plansList = list
    }
  },
  actions: {
    async updateUser({ commit }, { ctx, payload }) {
      console.log(`Form Data D ${payload}`)
      commit('changeSettingLoading', true)
      await axiosIns
        .post(defaultConfig.userUpdate, payload)
        .then((response) => {
          commit('changeSettingLoading', false)
          console.log('User Update Resp: ', response.data)
          if (response.data.success) {
            localStorage.setItem(
              'userData',
              JSON.stringify(response.data.userData)
            )
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Updated Successfully!`
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
          }
        })
        .catch((error) => {
          commit('changeSettingLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Update!`
            }
          })
        })
    },
    getUser({ commit, dispatch }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getUser)
          .then((response) => {
            console.log({ GettingUser: JSON.stringify(response.data) })
            if (response.data.success) {
              localStorage.setItem(
                'userData',
                JSON.stringify(response.data.data)
              )
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchPlans({ commit }, { ctx }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getPlansEndPoint)
          .then((response) => {
            console.log({
              FetchingInvoiceData: JSON.stringify(response.data)
            })
            if (response.data.success) {
              commit('changePlansList', response.data.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    generatePaymentLink({ commit }, { ctx, payload }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.generatePaymentEndPoint, payload)
          .then((response) => {
            console.log({
              GeneratingPaymentLink: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                return resolve(response.data)
              } else {
                return null
              }
            } else {
              return null
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
