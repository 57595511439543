import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    statusLoading: false,
    temporaryToken: '',
    mobile: ''
  },
  getters: {},
  mutations: {
    changeStatusLoading(state, val) {
      state.statusLoading = val
    },
    setTemporaryToken(state, token) {
      state.temporaryToken = token
    },
    removeTemporaryToken(state) {
      state.temporaryToken = ''
    },
    setMobile(state, mobile) {
      state.mobile = mobile
    },
    removeMobile(state) {
      state.mobile = ''
    }
  },
  actions: {
    async sendOTP({ commit }, { ctx, payload }) {
      let status = false
      console.log(`Form Data ${payload}`)
      commit('changeStatusLoading', true)
      await axiosIns
        .post(defaultConfig.sendOTP, payload)
        .then((response) => {
          commit('changeStatusLoading', false)
          console.log('Sent OTP: ', response.data)
          status = response.data.success
          if (response.data.success) {
            commit('setTemporaryToken', response.data.token)
            commit('setMobile', response.data.mobile)
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
          }
        })
        .catch((error) => {
          commit('changeStatusLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Send!`
            }
          })
        })
      return status
    },
    async verifyOTP({ commit, state }, { ctx, payload }) {
      let status = false
      console.log(`Form Data ${payload}`)
      commit('changeStatusLoading', true)
      await axiosIns
        .post(defaultConfig.verifyOTP + '/' + state.temporaryToken, payload)
        .then((response) => {
          commit('changeStatusLoading', false)
          console.log('Verify OTP: ', response.data)
          status = response.data.success
          if (response.data.success) {
            commit('setTemporaryToken', response.data.token)
            console.log('Token: ', state.temporaryToken)
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message
              }
            })
          }
        })
        .catch((error) => {
          commit('changeStatusLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Send!`
            }
          })
        })
      return status
    },
    async resetPassword({ commit, state }, { ctx, payload }) {
      let status = false
      console.log(`Form Data ${payload} Tv ${state.temporaryToken}`)
      commit('changeStatusLoading', true)
      await axiosIns
        .post(defaultConfig.resetPassword + '/' + state.temporaryToken, payload)
        .then((response) => {
          commit('changeStatusLoading', false)
          console.log('Reset Password: ', response.data)
          status = response.data.success
          if (response.data.success) {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Password Reset Successfully'
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: "Failed to Submit!"
              }
            })
          }
        })
        .catch((error) => {
          commit('changeStatusLoading', false)
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Submit!`
            }
          })
        })
      return status
    }
  }
}
