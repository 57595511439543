import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    NotificationLoading: false,
  },
  getters: {
  },
  mutations: {
    changeNotificationLoading(state, val) {
      state.NotificationLoading = val
    }
  },
  actions: {
    // Matters ------------------------------->>>>>>>>>

    // Get Matter
    fetchNotifications({ commit }, { ctx, queryParams }) {
      console.log('Fetch Notifications -->', queryParams)
      commit('changeNotificationLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getNotificationEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeNotificationLoading', false)
            console.log({
              FetchingMatterData: JSON.stringify(response.data.meta.total)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeNotificationLoading', false)
            reject(error)
          })
      })
    },
    readNotification({ commit }, { id }) {
      console.log('Read Notifications -->', id)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.readNotificationEndPoint(id))
          .then((response) => {
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    readAllNotification() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.readAllNotificationEndPoint)
          .then((response) => {
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

  }
}
