import defaultConfig from '@/configs/defaultConfig'
import axios from 'axios'

export default {
  namespaced: true,
  state: {
    newsList: [],
    newsLoading: false,
    currentNews: {},
  },
  getters: {},
  mutations: {
    setNewslist(state, payload) {
      state.newsList = payload
    },
    removeCurrentNewslist(state) {
      state.currentNews = null
    },
    setCurrentNews(state, payload) {
      state.currentNews = payload
      console.log("SN", state.currentNews)
    },
    changeNewsLoading(state, val) {
      state.newsLoading = val
    }
  },
  actions: {
    // Get Matter
    fetchNewsList({ commit }, { ctx, queryParams }) {
      console.log(queryParams)
      commit('changeNewsLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get(defaultConfig.getLawTrendNewsEndPoint, { params: queryParams })
          .then((response) => {
            console.log('News', response)
            if (response.status === 200) {
              if (response.data.length > 0) {
                commit('setNewslist', response.data)
              }
            }
            commit('changeNewsLoading', false)
            return resolve(response)
          })
          .catch((error) => {
            commit('changeNewsLoading', false)
            reject(error)
          })
      })
    },
    // Get Matter
    fetchNewsById({ commit }, { ctx, id }) {
      commit('changeNewsLoading', true)
      commit('removeCurrentNewslist')
      return new Promise((resolve, reject) => {
        axios
          .get(defaultConfig.getLawTrendNewsEndPoint+'/'+id)
          .then((response) => {
            console.log(response)
            commit('changeNewsLoading', false)
            if (response.status === 200) {
              if (response.data !== null) {
                commit('setCurrentNews', response.data)
              }
            }
            return resolve(response)
          })
          .catch((error) => {
            commit('changeNewsLoading', false)
            reject(error)
          })
      })
    }
  }
}
