import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    courtsData: {},
    allCourts: [],
    allCourtList: [],
    userCourts: []
  },
  getters: {
    // getAllCourts: (state) => {
    //   var data = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
    //   return data
    // },
  },
  mutations: {
    setAllCourtsToLocal(state, payload) {
      state.courtsData = payload.data
      localStorage.setItem(
        defaultConfig.allCourtsKeyName,
        JSON.stringify(payload.data)
      )
    },
    setUserCourtsToLocal(state, payload) {
      state.userCourts = payload.data
      localStorage.setItem(
        defaultConfig.userCourtsKeyName,
        JSON.stringify(payload.data)
      )
    },
    setAllCourts(state, payload) {
      state.allCourts = payload.courtsList
      state.allCourtList = payload.allC
    }
  },
  actions: {
    fetchCourts({ commit }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.allCourtEndPoint)
          .then((response) => {
            console.log({ CourtData: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setAllCourtsToLocal', response.data)
            }
          })
          .catch(console.log)
      })
    },

    fetchCourtTypes({ commit }, value) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getCaseTypesEndPoint({ id: value }))
          .then((response) => {
            console.log({ CourtData: JSON.stringify(response.data) })
            if (response.data.success) {
              return resolve(response.data['data'][0])
            } else {
              reject(error)
            }
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    fetchUserCourts({ commit }, ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.userCourtEndPoint)
          .then((response) => {
            console.log({ UserCourtData: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setUserCourtsToLocal', response.data)
            }
          })
          .catch(console.log)
      })
    },
    getAllCourts({ commit }, ctx) {
      let allC = []
      const courtsList = []
      let ll = JSON.parse(localStorage.getItem(defaultConfig.allCourtsKeyName))
      if (ll) {
        this.courtsData = ll
        const scl = ll.supremeCourts.map(function (key) {
          return {
            value: key._id,
            text: key.title,
            cslug: key.cli_slug,
            type: key.type,
            uniqueId: key.unique_id
          }
        })
        const sc = {
          label: 'Supreme Court',
          options: scl
        }
        const hcl = ll.highCourts.map(function (key) {
          return {
            value: key._id,
            text: key.title,
            cslug: key.cli_slug,
            type: key.type,
            uniqueId: key.unique_id
          }
        })
        const hc = {
          label: 'High Court',
          options: hcl
        }
        const dcl = ll.districtCourts.map(function (key) {
          return {
            value: key._id,
            text: key.title,
            cslug: key.cli_slug,
            type: key.type,
            uniqueId: key.unique_id
          }
        })
        const dc = {
          label: 'District Court',
          options: dcl
        }
        const tcl = ll.tribunalsCourts.map(function (key) {
          return {
            value: key._id,
            text: key.title,
            cslug: key.cli_slug,
            type: key.type,
            uniqueId: key.unique_id
          }
        })
        const tc = {
          label: 'Tribunals Court',
          options: dcl
        }
        courtsList.push(sc)
        courtsList.push(hc)
        courtsList.push(dc)
        courtsList.push(tc)
        allC = [...scl, ...hcl, ...dcl, ...tcl]

        let payload = {
          courtsList: courtsList,
          allC: allC
        }

        commit('setAllCourts', payload)
      }
      return allC
    }
  }
}
