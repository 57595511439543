import Vue from 'vue'
Vue.mixin({
  methods: {
    getUniqueId(data) {
      if(Object.keys(data).includes('unique_case_number') && data.unique_case_number !== null ){
        return data.unique_case_number
      }
      if (data.hc_name === 'sci') {
        var caset = ''
        if (data.case_type !== '') {
          caset = data.case_type + '/'
        }
        if (data.case_number !== null) {
          caset = caset + data.case_number + '/'
        }
        if (data.case_year !== null) {
          caset = caset + data.case_year
        }
        return caset
      }
      if (data.unique_id) {
        if (data.unique_id.includes('tribunals')) {
          return data.unique_id.includes('-')
            ? data.unique_id.split('-').slice(3).join('/')
            : data.unique_id
        }
        return data.unique_id.includes('-')
          ? data.unique_id.split('-').slice(2).join('/')
          : data.unique_id
      } else {
        return ''
      }
    },
    getStringCourtNumber(court_number) {
      switch (court_number) {
        case -10:
          return "C.J."
        case -15:
          return "In Chamber"
        default:
          return "N/A"
      }
    }
  }
})
