import { getUserData } from '@/auth/utils'
import moment from 'moment'

export default [
    // FIR Trackers
  {
    path: '/trackers/firtracker/fir-tracker-list',
    name: 'fir-tracker-list',
    component: () =>
      import(
        '@/views/trackers/firtracker/fir-tracker-list/FIRTrackerList.vue'
      ),
    meta: {
      pageTitle: 'FIR Tracker',
      breadcrumb: [
        {
          text: 'FIR Tracker',
          active: true
        },
        {
          text: 'Trackers',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/trackers/firtracker/fir-preview/:id/:unique_id',
    name: 'trackers-fir-preview',
    component: () =>
      import('@/views/trackers/firtracker/fir-preview/FIRPreview.vue'),
    meta: {
      pageTitle: 'Case Detail',
      breadcrumb: [
        {
          text: 'Trackers',
          active: true
        },
        {
          text: 'Case Details',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  // SCI Tracker
  {
    path: '/trackers/sci-tracker/tracker-list',
    name: 'sci-tracker-list',
    component: () =>
      import(
        '@/views/trackers/sci-tracker/tracker-list/TrackerList.vue'
      ),
    meta: {
      pageTitle: 'Appeal Tracker',
      breadcrumb: [
        {
          text: 'Appeal Trackers',
          active: true
        },
        {
          text: 'Trackers',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  // Caveat Tracker
  {
    path: '/trackers/up-caveat/tracker-list',
    name: 'up-caveat-list',
    component: () =>
      import(
        '@/views/trackers/up-caveat/tracker-list/TrackerList.vue'
      ),
    meta: {
      pageTitle: 'Caveat Tracker',
      breadcrumb: [
        {
          text: 'Caveat Tracker',
          active: true
        },
        {
          text: 'Trackers',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
  {
    path: '/trackers/up-caveat/up-caveat-preview/:id/:unique_id',
    name: 'up-caveat-preview',
    component: () =>
      import('@/views/trackers/up-caveat/up-caveat-preview/UPCaveatPreview.vue'),
    meta: {
      pageTitle: 'Case Detail',
      breadcrumb: [
        {
          text: 'Caveat Tracker',
          active: true
        },
        {
          text: 'Case Details',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    },
    beforeEnter(to, _, next) {
      const userData = getUserData()
      if (userData.enabled == false) {
        next({ name: 'account-disabled' })
      } else {
        let expiredDate = moment(userData.expires_at)
        console.log('User Expired: ', expiredDate.isBefore(moment()))
        if (expiredDate.isBefore(moment())) {
          next({ name: 'account-expired' })
        } else next()
      }
    }
  },
]
