import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    allFIR: [],
    firDistrictList: [],
    isFIRLoading: false,
    isNewFIRLoading: false,
    isFIRCaseLoading: false,
    currentFIRCase: null,
    currentFIR: null
  },
  getters: {},
  mutations: {
    changeFIRLoading(state, val) {
      state.isFIRLoading = val
    },
    changeFIRCaseLoading(state, val) {
      state.isFIRCaseLoading = val
    },
    clearCurrentFIRCase(state) {
      state.currentFIRCase = null
      state.currentFIR = null
    },
    setCurrentFIR(state, payload) {
      state.currentFIR = payload.data
    },
    setCurrentFIRCaseData(state, payload) {
      state.currentFIRCase = payload
    },
    updateFIRDistrictListLoading(state, val) {
      state.firDistrictList = val
    },
    changeNewFIRLoading(state, val) {
      state.isNewFIRLoading = val
    },
    setInvoiceList(state, val) {
      state.allFIR = val
    },
    clearInvoiceList(state, val) {
      state.allFIR = []
    }
  },
  actions: {
    // Invoices ------------------------------->>>>>>>>>
    fetchUPDistrict({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getUPDistrictEndPoint)
          .then((response) => {
            console.log({
              FetchingDistrictData: JSON.stringify(response.data)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // Get FIR
    fetchList({ commit }, { ctx, queryParams }) {
      console.log('Fetch Matters -->', queryParams)
      commit('changeFIRLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getUPCaveatListEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeFIRLoading', false)
            console.log({
              FetchingListData: JSON.stringify(response.data.meta.total)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeFIRLoading', false)
            reject(error)
          })
      })
    },

    // Get Matter
    fetchCase({ commit }, { ctx, id, unique_id }) {
      console.log('Matter Id -->', id)
      commit('clearCurrentFIRCase')
      commit('changeFIRCaseLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getUPCaveatItemByIDEndPoint( id ))
          .then((response) => {
            commit('changeFIRCaseLoading', false)
            console.log({
              'Matter-->': JSON.stringify(response.data)
            })
            if (response.data.success) {
              console.log('U', unique_id)
              var data = response.data
              commit('setCurrentFIR', data)
              console.log("Case", data.data.data)
              if (data.data.data.length) {
                const foundItem = data.data.data.find(
                  (item) => item.unique_id === unique_id
                )
                if (foundItem) {
                  commit('setCurrentFIRCaseData', foundItem)
                  console.log(foundItem)
                }
              }
              commit('setCurrentFIR', response.data)
            }
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeFIRCaseLoading', false)
            reject(error)
          })
      })
    },

    addTracker({ commit }, { ctx, payload }) {
      console.log(payload)
      commit('changeNewFIRLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.addNewUPCaveatEndPoint, payload)
          .then((response) => {
            commit('changeNewFIRLoading', false)
            console.log({
              SavingFIRData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$bvModal.hide('modal-create-up-caveat')
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Tracker saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to add tracker!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewFIRLoading', false)
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    addToMatter({ commit, dispatch }, { ctx, payload }){
      return new Promise((resolve, reject) => {
        axiosIns
          .put(defaultConfig.addToMatterUPCaveatItemEndPoint, payload)
          .then((response) => {
            console.log('Data', response.data)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Case Added successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    },

    // Delete Invoice
    deleteTracker({ commit, dispatch }, { ctx, _id }) {
      console.log('Invoice Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteUPCaveatItemEndPoint(_id))
          .then((response) => {
            console.log('Data', response.data)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'FIR deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    }
  }
}
