import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    allInvoices: [],
    isInvoiceLoading: false
  },
  getters: {},
  mutations: {
    changeInvoiceLoading(state, val) {
      state.isInvoiceLoading = val
    },
    setInvoiceList(state, val) {
      state.allInvoices = val
    },
    clearInvoiceList(state, val) {
      state.allInvoices = []
    }
  },
  actions: {
    // Invoices ------------------------------->>>>>>>>>

    // Get Invoices
    fetchInvoices({ commit }, { ctx, queryParams }) {
      console.log('Setch Matters -->', queryParams)
      commit('changeInvoiceLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getInvoiceEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeInvoiceLoading', false)
            console.log({
              FetchingInvoiceData: JSON.stringify(response.data.meta.total)
            })
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeInvoiceLoading', false)
            reject(error)
          })
      })
    },

    fetchMatterInvoice(ctx, matterId) {
      console.log('invoice matter Id --->', matterId)
      ctx.commit('changeInvoiceLoading', true)
      ctx.commit('clearInvoiceList')
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getInvoiceEndPoint, {
            params: {
              perPage: 200,
              page: 1,
              'filters[matter_id]': matterId
            }
          })
          .then((response) => {
            ctx.commit('changeInvoiceLoading', false)
            console.log({
              FetchingInvoice: JSON.stringify(response.data)
            })
            if (response.data.success) {
              ctx.commit('setInvoiceList', response.data.data)
            }
          })
          .catch((error) => {
            ctx.commit('changeInvoiceLoading', false)
            reject(error)
          })
      })
    },

    saveInvoice({ commit }, { ctx, payload }) {
      if (payload.name && payload.subject && payload.products.length > 0) {
        var status = true
        for (let i = 0; i < payload.products.length; i++) {
          console.log(payload.products[i])
          if (
            parseInt(payload.products[i].price) >= 0 &&
            payload.products[i].item_name
          ) {
            status = true
          } else {
            status = false
            break
          }
        }
        if (status) {
          console.log(payload)
          commit('changeInvoiceLoading', true)
          return new Promise((resolve, reject) => {
            axiosIns
              .post(defaultConfig.saveInvoiceEndPoint, payload)
              .then((response) => {
                commit('changeInvoiceLoading', false)
                console.log({
                  SavingInvoiceData: JSON.stringify(response.data)
                })
                if (Object.keys(response.data).includes('success')) {
                  if (response.data.success) {
                    ctx.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Invoice saved successfully!',
                        icon: 'CoffeeIcon',
                        variant: 'success'
                      }
                    })
                    return resolve(response.data)
                  } else {
                    ctx.$toast({
                      component: ToastificationContent,
                      props: {
                        title: '' + response.data.message,
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                      }
                    })
                    return null
                  }
                } else {
                  ctx.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Server Error, Please try again!',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger'
                    }
                  })
                  return null
                }
              })
              .catch((error) => {
                commit('changeInvoiceLoading', false)
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong, Please try again!',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                reject(error)
              })
          })
        } else {
          ctx.$toast({
            component: ToastificationContent,
            props: {
              title: 'Enter mandatory fields!',
              icon: 'CoffeeIcon',
              variant: 'success'
            }
          })
        }
      } else {
        ctx.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enter mandatory fields!',
            icon: 'CoffeeIcon',
            variant: 'success'
          }
        })
      }
    },

    // Delete Invoice
    deleteInvoice({ commit, dispatch }, { ctx, _id }) {
      console.log('Invoice Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(defaultConfig.deleteInvoiceEndPoint(_id))
          .then((response) => {
            console.log('Data', response.data)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Invoice deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                ctx.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              ctx.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            ctx.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    }

    // Save Invoice
    // saveMatter({ commit }, { ctx, payload }) {
    //   commit('changeNewMatterLoading', true)
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .put(defaultConfig.saveMatterEndPoint, payload)
    //       .then((response) => {
    //         commit('changeNewMatterLoading', false)
    //         console.log({
    //           SavingMatterData: JSON.stringify(response.data)
    //         })
    //         if (Object.keys(response.data).includes('success')) {
    //           if (response.data.success) {
    //             ctx.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: 'Matter saved successfully!',
    //                 icon: 'CoffeeIcon',
    //                 variant: 'success'
    //               }
    //             })
    //             ctx.$bvModal.hide('modal-create-matter')
    //             return resolve(response.data)
    //           } else {
    //             ctx.$toast({
    //               component: ToastificationContent,
    //               props: {
    //                 title: '' + response.data.message,
    //                 icon: 'AlertTriangleIcon',
    //                 variant: 'danger'
    //               }
    //             })
    //             return null
    //           }
    //         } else {
    //           ctx.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: 'Server Error, Please try again!',
    //               icon: 'AlertTriangleIcon',
    //               variant: 'danger'
    //             }
    //           })
    //           return null
    //         }
    //       })
    //       .catch((error) => {
    //         commit('changeNewMatterLoading', false)
    //         ctx.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Something went wrong, Please try again!',
    //             icon: 'AlertTriangleIcon',
    //             variant: 'danger'
    //           }
    //         })
    //         reject(error)
    //       })
    //   })
    // },
  }
}
