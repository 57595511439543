import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    // courtsData: {}
    displayBoardLoading: false,
    displayBoardCasesLoading: false,
    displayBoardData: [],
    displayBoardCasesData: [],
    userDisplayBoardData: [],
    tableFields: []
  },
  getters: {},
  mutations: {
    changeDisplayBoardLoading(state, val) {
      state.displayBoardLoading = val
    },
    changeDisplayBoardCasesLoading(state, val) {
      state.displayBoardCasesLoading = val
    },
    clearDisplayBoard(state) {
      state.displayBoardData = []
    },
    clearDisplayBoardCases(state) {
      state.displayBoardCasesData = []
    },
    clearUserDisplayBoard(state) {
      state.userDisplayBoardData = []
    },

    setDisplayBoardCasesData(state, payload) {
      state.displayBoardCasesData = payload
    },

    setDisplayBoardData(state, payload) {
      const court = payload[0]
      const srNo = payload[1]
      const list = payload.length > 2 ? payload[2] : []
      const progress = payload.length > 3 ? payload[3] : []
      let dbdata = []
      if (srNo) {
        for (let i = 0; i < srNo.length; i++) {
          if (i !== 0) {
            state.tableFields = [
              {
                key: 'Court',
                label: 'Court'
                // formatter: (value, key, item) => {
                //   // Customize the "Court" data here
                //   return `<a href="${item.link}">${value}</a>`
                // }
              },
              {
                key: 'Sr. No.',
                label: 'Sr. No.'
              }
            ]
            const data = {
              Court: court[i],
              'Sr. No.': srNo[i]
            }
            if (list.length) {
              state.tableFields.push({
                key: 'List',
                label: 'List'
              })
              data['List'] = list[i]
            }
            if (progress.length) {
              state.tableFields.push({
                key: 'Progress',
                label: 'Progress'
              })
              data['Progress'] = progress[i]
            }
            console.log('data', data)
            dbdata.push(data)
          }
        }
      }
      state.displayBoardData = dbdata
      console.log({ UDisplayBoard: JSON.stringify(state.displayBoardData) })
    },
    setUserDisplayBoardData(state, payload) {
      state.userDisplayBoardData = payload
      console.log({ DisplayBoard: JSON.stringify(state.userDisplayBoardData) })
    }
  },
  actions: {
    fetchDisplayBoard({ commit }, { ctx, value }) {
      commit('changeDisplayBoardLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getDisplayBoardEndPoint({ id: value }))
          .then((response) => {
            commit('clearDisplayBoard')
            commit('changeDisplayBoardLoading', false)
            console.log({ FetchingDisplayBoard: JSON.stringify(response.data) })
            if (response.data.success) {
              commit('setDisplayBoardData', response.data.data)
            }
          })
          .catch((error) => {
            commit('changeDisplayBoardLoading', false)
            commit('clearDisplayBoard')
            reject(error)
          })
      })
    },
    fetchDisplayCasesBoard({ commit }, { ctx, value, payload }) {
      commit('changeDisplayBoardCasesLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.getDisplayBoardEndPoint({ id: value }), payload)
          .then((response) => {
            commit('clearDisplayBoardCases')
            commit('changeDisplayBoardCasesLoading', false)
            console.log({ FetchingDisplayBoard: JSON.stringify(response.data) })
            if (response.data.success) {
              console.log('Cases', response.data)
              commit('setDisplayBoardCasesData', response.data.data)
            }
          })
          .catch((error) => {
            commit('changeDisplayBoardCasesLoading', false)
            commit('clearDisplayBoardCases')
            reject(error)
          })
      })
    },
    fetchUserDisplayBoard({ commit }) {
      let userData = JSON.parse(localStorage.getItem('userData'))
      if (userData?.court_id) {
        return new Promise((resolve, reject) => {
          axiosIns
            .get(
              defaultConfig.getDisplayBoardEndPoint({ id: userData.court_id })
            )
            .then((response) => {
              console.log({
                FetchingUserDisplayBoard: JSON.stringify(response.data)
              })
              if (response.data.success) {
                commit('setUserDisplayBoardData', response.data.data)
                return resolve(response.data.data)
              } else {
                commit('clearUserDisplayBoard')
                return null
              }
            })
            .catch((error) => {
              reject(error)
              commit('clearUserDisplayBoard')
              return null
            })
        })
      } else {
        commit('clearUserDisplayBoard')
        return null
      }
    },
    async updateUser({ commit }, { ctx, payload }) {
      console.log(`Form Data Displayboard ${payload}`)
      await axiosIns
        .post(defaultConfig.userUpdate, payload)
        .then((response) => {
          console.log('User Update Resp: ', response.data)
          if (response.data.success) {
            localStorage.setItem(
              'userData',
              JSON.stringify(response.data.userData)
            )
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success!`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `Default Display Board Updated Successfully!`
              }
            })
          } else {
            ctx.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Failed!`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: `Something went wrong, Please Try again!`
              }
            })
          }
        })
        .catch((error) => {
          console.log(`Error ${error}`)
          ctx.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Failed!`,
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: `Failed to Update Default Display Board!`
            }
          })
        })
    }
  }
}
