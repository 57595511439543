import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import bus from '@/configs/eventBus'

export default {
  namespaced: true,
  state: {
    allTasks: [],
    isTaskLoading: false,
    isNewTaskLoading: false,
    currentTask: null,
    selectedCalendars: ['Personal', 'Business', 'Matter', 'Chamber', 'ETC'],
    calendarOptions: [
      {
        color: 'info',
        label: 'Matter'
      },
      {
        color: 'primary',
        label: 'Business'
      },
      {
        color: 'secondary',
        label: 'Chamber'
      },
      {
        color: 'warning',
        label: 'Personal'
      },
      {
        color: 'danger',
        label: 'ETC'
      }
    ]
  },
  getters: {},
  mutations: {
    setSelectedTaskTypes(state, val) {
      state.selectedCalendars = val
    },
    changeTaskLoading(state, val) {
      state.isTaskLoading = val
    },
    setCurrentTask(state, payload) {
      state.currentTask = payload.data
    },
    changeNewTaskLoading(state, val) {
      state.isNewTaskLoading = val
    },
    setTasksList(state, val) {
      state.allTasks = val
    },
    clearTaskList(state, val) {
      state.allTasks = []
    }
  },
  actions: {
    // Get List
    fetchList({ commit }, { ctx, queryParams }) {
      console.log('Fetch Tasks -->', queryParams)
      commit('changeTaskLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(defaultConfig.getTaskListEndPoint, { params: queryParams })
          .then((response) => {
            commit('changeTaskLoading', false)
            console.log({
              FetchingTaskListData: JSON.stringify(response.data)
            })
            const newArray = response.data.data.map((item) => {
              return {
                ...item,
                id: item._id,
                matter: item.matter ? item.matter : null
              }
            })
            console.log(JSON.stringify(newArray))
            return resolve({ data: newArray })
          })
          .catch((error) => {
            commit('changeTaskLoading', false)
            reject(error)
          })
      })
    },

    addTask({ commit }, { ctx, payload }) {
      console.log(payload)
      commit('changeNewTaskLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.addTaskEndPoint, payload)
          .then((response) => {
            commit('changeNewTaskLoading', false)
            console.log({
              SavingTaskData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                // bus.$bvModal.hide('modal-create-up-caveat')
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Task saved successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              bus.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to add task!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewTaskLoading', false)
            bus.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    updateTask({ commit }, { ctx, _id, payload }) {
      console.log(payload)
      commit('changeNewTaskLoading', true)
      return new Promise((resolve, reject) => {
        axiosIns
          .put(defaultConfig.updateTaskEndPoint(_id), payload)
          .then((response) => {
            commit('changeNewTaskLoading', false)
            console.log({
              SavingTaskData: JSON.stringify(response.data)
            })
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                //   bus.$bvModal.hide('modal-create-up-caveat')
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Task updated successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              bus.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Failed to add task!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            commit('changeNewTaskLoading', false)
            bus.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
          })
      })
    },

    // Delete Task
    deleteTask({ commit, dispatch }, { ctx, _id }) {
      console.log('Task Id: ', _id)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(defaultConfig.deleteTaskEndPoint(_id))
          .then((response) => {
            console.log('Data', response.data)
            if (Object.keys(response.data).includes('success')) {
              if (response.data.success) {
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Task deleted successfully!',
                    icon: 'CoffeeIcon',
                    variant: 'success'
                  }
                })
                return resolve(response.data)
              } else {
                bus.$toast({
                  component: ToastificationContent,
                  props: {
                    title: '' + response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                  }
                })
                return null
              }
            } else {
              bus.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Server Error, Please try again!',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                }
              })
              return null
            }
          })
          .catch((error) => {
            bus.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong, Please try again!',
                icon: 'AlertTriangleIcon',
                variant: 'danger'
              }
            })
            reject(error)
            return null
          })
      })
    }
  }
}
