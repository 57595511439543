export default [
  {
    path: '/settings/account-setting',
    name: 'account-setting',
    component: () =>
      import('@/views/settings/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Settings'
        },
        {
          text: 'Account Settings',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/SupportTicket.vue'),
    meta: {
      pageTitle: 'Support',
      breadcrumb: [
        {
          text: 'Suppor Ticket',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    }
  },
  {
    path: '/settings/account-setting',
    name: 'account-expired',
    component: () =>
      import('@/views/settings/account-setting/AccountExpired.vue'),
    meta: {
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Plan Expired',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    }
  },
  {
    path: '/settings/account-setting',
    name: 'account-disabled',
    component: () =>
      import('@/views/settings/account-setting/AccountDisabled.vue'),
    meta: {
      pageTitle: 'Account',
      breadcrumb: [
        {
          text: 'Account Disabled',
          active: true
        }
      ],
      action: { NewMatterButtonEnabled: false }
    }
  }
]
